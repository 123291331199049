import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DataTable from "../../components/data-table/DataTable";
import {
    createRegion,
    getReferralPrograms,
    getRegions,
    navLinks,
    emptyRegionSubscriptions,
    emptySocialNetworkLink
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Fab,
    Grid, MenuItem
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import Add from "@material-ui/icons/Add";
import {
    fetchSocialNetworkCodes,
    getCurrencies,
    getMessengers,
    getRegionEvents,
    getTelegramChats,
    getTimeZones
} from "../../services/common";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash"
import {withTranslation} from "react-i18next";
import CheckboxField from "../../components/fields/CheckboxField";
import FieldsList from "../../components/fields/FieldsList";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import TimeField from "../../components/fields/TimeField";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import {isValidRequiredL18nString} from "../../utils/validators";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {getPriceBy, priceStatus} from "../../services/facilities";

const columnData = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Название' },
];

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    checkBox: {
        marginTop: 15
    },
    del: {
        display: 'flex',
        alignItems: 'flex-end'
    },
});

const emptyRegion = {
    id: null,
    name: null,
    nameAll: {},
    code: null,
    minCashValues: [],
    cashChangeInMainCurrency: false,
    referralProgramId: null,
    hasRiskyStatus: false,
    subscriptions: [],
    botConfirmVisitsTime: null,
    botCreateUnconfirmedVisitsTaskTime: null,
    hasPaymentLinks: false,
    visitCreateTime: 2,
    spendingSpreadSheetId: null,
    timeZone: null,
    socialNetworkLinks: [],
    regionBitrixId: null,
    price: null,
};

const emptyMinCashValue = {
    currency: null,
    value: 1
}

@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show
})
class RegionListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        region: emptyRegion,
        dialogEditOpen: false,
        refreshTable: false,
        currencies: [],
        programs: [],
        vat: null,
        regionEvents: [],
        messengers: [],
        timeZones: [],
        socialNetworkCodes: [],
        prices: [],
    };

    componentDidMount() {
        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data
                    });
                }
            });

        getReferralPrograms()
            .then(response => {
                if (response.success) {
                    this.setState({
                        programs: response.data
                    });
                }
            });

        getMessengers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        messengers: response.data
                    })
                }
            });

        getRegionEvents()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regionEvents: response.data
                    })
                }
            });
        getTelegramChats()
            .then(response => {
                if (response.success) {
                    this.setState({
                        telegramChats: response.data
                    })
                }
            });

        getTimeZones()
            .then(response => {
                if (response.success) {
                    this.setState({
                        timeZones: response.data
                    });
                }
            });

        fetchSocialNetworkCodes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        socialNetworkCodes: response.data
                    });
                }
            });

        getPriceBy({status: priceStatus.active})
            .then(response => {
                if (response.success) {
                    this.setState({
                        prices: response.data
                    })
                }
            })
    }

    handleChangeTime = (prop) => moment => {
        this.setState({
            region: {
                ...this.state.region,
                [prop]: moment.utcOffset(0).format(),
            }
        });
    }

    handleChange = (prop) => event => {
        if (prop === 'vat' && !(+event.target.value)) {
            return;
        }

        this.setState({
            region: {
                ...this.state.region,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeI18n = event => {
        const [prop, locale] = event.target.id.split('#');
        this.setState({
            region: {
                ...this.state.region,
                [prop]: {
                    ...this.state.region[prop],
                    [locale]: event.target.value,
                }
            }
        });
    };

    handleEdit = region => {
        this.setState({
            region,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        createRegion(this.state.region)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSubArr = (prop, subProp, index) => event => {
        let subArr = [...this.state.region[prop]];
        subArr[index][subProp] = event.target.value;
        this.setState({
            region: {
                ...this.state.region,
                [prop]: subArr
            },
        });
    };

    handleDeleteSubArr = (prop, index) => () => {
        let subArr = [...this.state.region[prop]];
        subArr.splice(index, 1);
        this.setState({
            region: {
                ...this.state.region,
                [prop]: subArr
            },
        });
    };

    handleAddSubArr = (prop) => () => {
        let subArr = [...this.state.region[prop]];
        subArr.push({ ...emptyMinCashValue });
        this.setState({
            region: {
                ...this.state.region,
                [prop]: subArr
            },
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.setState({
            region: emptyRegion,
            dialogEditOpen: true,
        })
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getRegions(search, ...params);
    };

    isSaveDisabled() {
        const { region } = this.state;

        return !isValidRequiredL18nString(region.nameAll)
            || !region.botConfirmVisitsTime
            || !region.botCreateUnconfirmedVisitsTaskTime
            || !region.price;
    }

    render() {
        const { classes, t } = this.props;
        const {
            search,
            dialogEditOpen,
            refreshTable,
            region,
            currencies,
            programs,
            messengers,
            regionEvents,
            telegramChats,
            timeZones,
            socialNetworkCodes,
            prices,
        } = this.state;

        const selectedCurrencies = _.flatMap(region.minCashValues, (minCashValue) => {
            if (minCashValue.currency) {
                return minCashValue.currency
            }
        });

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <SearchField
                    value={search.search}
                    onChange={this.handleChangeSearch('search')}
                    onClick={this.handleClickSearch}
                    label="Поиск"
                />
                <br />
                <br />
                <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
            </LeftBar>
            <Content title="Регионы">
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getTableData}
                    refresh={refreshTable}
                    onClick={this.handleEdit}
                    selector={false}
                />
            </Content>

            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="dialog-title">Карточка региона</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#en"
                                label="Название региона, EN"
                                value={region.nameAll.en}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#ru"
                                label="Название региона, RU"
                                value={region.nameAll.ru}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#cs"
                                label="Название региона, CS"
                                value={region.nameAll.cs}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AnyField
                                label="Код региона"
                                value={region.code}
                                onChange={this.handleChange('code')}
                                required
                                error={!region.code}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Временная зона"
                                value={region.timeZone}
                                error={!region.timeZone}
                                name="timeZone"
                                onChange={this.handleChange('timeZone')}
                                fullWidth
                                select
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {timeZones.map(item => <MenuItem key={item.offset} value={item.offset}>{item.offset}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteSelectField
                                label="Основной прайс"
                                error={!region.price}
                                value={region.price}
                                name="price"
                                onChange={this.handleChange('price')}
                                fullWidth
                                select
                                options={prices.map(item => ({value: item.id, label: item.name}))}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                label="Сдача выдаётся в основной валюте"
                                value={region.cashChangeInMainCurrency}
                                onChange={this.handleChange('cashChangeInMainCurrency')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            { region.minCashValues.map( (minCashValue, index) => (
                                    <Grid container spacing={2} key={ index }>
                                        <Grid item xs={5}>
                                            <AnyField
                                                label="Валюта"
                                                value={minCashValue.currency}
                                                required
                                                fullWidth
                                                error={!minCashValue.currency}
                                                onChange={this.handleChangeSubArr('minCashValues', 'currency', index)}
                                                select
                                            >
                                                {currencies.map(item => {
                                                    if (selectedCurrencies.indexOf(item.id) === -1 || selectedCurrencies.indexOf(item.id) === index) {
                                                        return <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>;
                                                    }

                                                    return null;
                                                })}
                                            </AnyField>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <AnyField
                                                label={ "Значение" }
                                                value={ minCashValue.value}
                                                required
                                                type="number"
                                                error={!minCashValue.value || minCashValue.value <= 0}
                                                fullWidth
                                                onChange={this.handleChangeSubArr('minCashValues', 'value', index)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} className={classes.del}>
                                            <Fab color="secondary" onClick={this.handleDeleteSubArr('minCashValues', index)} size={"small"}><ClearIcon/></Fab>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <ActionButton
                                onClick={this.handleAddSubArr('minCashValues')}
                                disabled={selectedCurrencies.length === currencies.length}
                                fullWidth={false}
                            >
                                <Add />
                                Добавить минимальную сдачу
                            </ActionButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AnyField
                                label="Реферальная программа"
                                value={region.referralProgramId}
                                onChange={this.handleChange('referralProgramId')}
                                fullWidth
                                select
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {programs.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label={t('VAT')}
                                value={region.vat}
                                onChange={this.handleChange('vat')}
                                fullWidth
                                />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CheckboxField
                                label="Рискованные визиты"
                                value={region.hasRiskyStatus}
                                onChange={this.handleChange('hasRiskyStatus')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                label="Публичные ссылки на оплату"
                                value={region.hasPaymentLinks}
                                onChange={this.handleChange('hasPaymentLinks')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Время на создание визита, мин"
                                required
                                value={region.visitCreateTime}
                                error={!region.visitCreateTime}
                                InputProps={{
                                    inputComponent: NumberFormatCount,
                                }}
                                onChange={this.handleChange('visitCreateTime')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Id google-таблицы для расходов"
                                value={region.spendingSpreadSheetId}
                                onChange={this.handleChange('spendingSpreadSheetId')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="BitrixId региона"
                                value={region.regionBitrixId}
                                onChange={this.handleChange('regionBitrixId')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <FieldsList
                            items={region.subscriptions}
                            emptyItem={emptyRegionSubscriptions}
                            fullWidth
                            label={t('Bot subscriptions')}
                            onChange={this.handleChange('subscriptions')}
                            headers={['Событие', 'Тип сообщения', 'Получатель']}
                            fields={[
                                (item, onChange) => <SelectFromItemsField
                                    value={item.regionEvent}
                                    items={regionEvents}
                                    onChange={onChange('regionEvent')}
                                    required
                                    fullWidth
                                    textField="text"
                                />,
                                (item, onChange) => <SelectFromItemsField
                                    value={item.messenger}
                                    items={messengers}
                                    onChange={onChange('messenger')}
                                    required
                                    fullWidth
                                    textField="text"
                                />,
                                (item, onChange) => {
                                    return item.messenger === 'telegram' && telegramChats && telegramChats.length > 0 ?
                                    <AnyField
                                        value={item.recipient}
                                        onChange={onChange('recipient')}
                                        required
                                        fullWidth
                                        error={!item.recipient}
                                        select
                                    >
                                        {telegramChats.map(item => <MenuItem key={item.id} value={item.telegramId}>{item.title}</MenuItem>)}
                                    </AnyField>
                                    :
                                    <AnyField
                                        value={item.recipient}
                                        onChange={onChange('recipient')}
                                        required
                                        fullWidth
                                        error={!item.recipient}
                                    />
                                }
                            ]}
                        />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TimeField
                                label="Проверять неподтверждение визиты в"
                                required
                                value={region.botConfirmVisitsTime}
                                error={!region.botConfirmVisitsTime}
                                onChange={this.handleChangeTime('botConfirmVisitsTime')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimeField
                                label="Ставить задачи по неподтвержденным визитам в"
                                required
                                value={region.botCreateUnconfirmedVisitsTaskTime}
                                error={!region.botCreateUnconfirmedVisitsTaskTime}
                                onChange={this.handleChangeTime('botCreateUnconfirmedVisitsTaskTime')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <FieldsList
                            items={region.socialNetworkLinks}
                            emptyItem={emptySocialNetworkLink}
                            fullWidth
                            label={t('Social networks')}
                            onChange={this.handleChange('socialNetworkLinks')}
                            headers={['Ссылка', 'Тип']}
                            fields={[
                                (item, onChange) => <AnyField
                                    value={item.link}
                                    onChange={onChange('link')}
                                    required
                                    fullWidth
                                />,
                                (item, onChange) => <SelectFromItemsField
                                    value={item.code}
                                    items={socialNetworkCodes}
                                    onChange={onChange('code')}
                                    required
                                    fullWidth
                                    textField="text"
                                />,
                            ]}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={this.isSaveDisabled()} color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>;
    }
}

export default withStyles(styles)(RegionListPage);
